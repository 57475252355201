import { defineStyleConfig } from '@chakra-ui/react';

export const buttonTheme = defineStyleConfig({
    variants: {
        solid: {
            bgGradient: 'linear(to-b, var(--chakra-colors-brand-primaryGradientColor1), var(--chakra-colors-brand-primaryGradientColor2))',
            _hover: {
                bgGradient: 'linear(to-b, var(--chakra-colors-brand-primaryGradientColor1), var(--chakra-colors-brand-primaryGradientColor2))',
                _disabled: { bgColor: 'gray', color: 'black', boxShadow: 'none' },
                boxShadow: 'outlinePrimaryGreen'
            },
            _active: {
                bgGradient: 'linear(to-b, var(--chakra-colors-brand-primaryGradientColor1), var(--chakra-colors-brand-primaryGradientColor2))'
            },
            color: 'black',
            borderRadius: '3px',
            minW: 'fit-content'
        },
        outline: {
            border: '0',
            borderRadius: '3px',
            bgColor: 'white',
            color: 'black',
            _hover: {
                bgColor: 'white',
                _disabled: { bgColor: 'gray', color: 'black', boxShadow: 'none' },
                boxShadow: 'outlineLightGray'
            },
            minW: 'fit-content'
        },
        black: {
            bgColor: 'black',
            _hover: {
                _disabled: { bgColor: 'gray', color: 'black', boxShadow: 'none' },
                boxShadow: 'outlineLightGray'
            },
            color: 'white',
            borderRadius: '3px',
            minW: 'fit-content'
        },
        pink: {
            bgGradient: 'linear(to-r, #FF69B4, #FF1493)',
            _hover: {
                _disabled: { bgColor: 'gray', color: 'black', boxShadow: 'none' },
                boxShadow: 'outlineLightGray'
            },
            color: 'white',
            borderRadius: '3px',
            minW: 'fit-content'
        }
    },
    defaultProps: {
        size: 'md',
        variant: 'solid'
    }
});
